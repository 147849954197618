<template>
    <div id="sendEmailVerification">

        <div v-if="allowEditEmail" class="card-body p-4">
            <h5 class="mb-1 text-center">{{$t('sendEmailVerification.change.titolo')}}</h5>
            <p> {{$t('sendEmailVerification.change.descrizione')}} </p>
            <form class="form-horizontal" @submit.prevent="onConfirmNewEmail()">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group form-group-custom mb-2">
                            <input type="text" class="form-control" id="username" v-model.trim="$v.form.email.$model" required>
                            <label for="username">{{$t('sendEmailVerification.form.email.label')}}</label>
                            <div class="error pl-vertical-align-input" v-if="!$v.form.email.email">{{$t('sendEmailVerification.form.email.erroreFormato')}}</div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-6 mt-2 d-md-block d-none">
                                <button class="btn btn-danger btn-block waves-effect waves-light" type="button" @click="onCancelNewEmail()">{{$t('sendEmailVerification.change.cancelButton')}}</button>
                            </div>
                            <div class="col-md-6 mt-2 ">
                                <button class="btn btn-primary btn-block waves-effect waves-light" type="submit" >{{$t('sendEmailVerification.change.confirmButton')}}</button>
                            </div>
                            <div class="col-md-6 mt-2 d-md-none">
                                <button class="btn btn-danger btn-block waves-effect waves-light" type="button" @click="onCancelNewEmail()">{{$t('sendEmailVerification.change.cancelButton')}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <div class="text-center mt-4">
                <a target="_blank" href="https://www.concorsando.it/blog/contattaci" rel="noopener">{{$t('components.login.haiBisognoDiAiuto')}}</a>
            </div>
        </div>
        <div v-else class="card-body p-4">
            <h5 class="mb-1 text-center">{{$t('sendEmailVerification.verify.titolo')}}</h5>
            <p> {{$t('sendEmailVerification.verify.descrizione_line1_part1')}}{{emailComunicazioni}}{{$t('sendEmailVerification.verify.descrizione_line1_part2')}} </p>
            <p> {{$t('sendEmailVerification.verify.descrizione_line2')}} </p>
            <form class="form-horizontal">
                <div class="row">
                    <div class="col-md-12">
                        <div class="mt-2">
                            <button class="btn btn-success btn-block waves-effect waves-light" type="button" @click="onHoVerificato()">{{$t('sendEmailVerification.verify.buttonHoVerificato')}}</button>
                        </div>
                        <div class="mt-2">
                            <button class="btn btn-primary btn-block waves-effect waves-light" type="button" @click="onSendNewVerification()">{{$t('sendEmailVerification.verify.buttonInviaNuovaEmail')}}</button>
                        </div>
                    </div>
                </div>
            </form>
            <p v-if="canChangeEmail" class="mt-2 font-size-12">
                <a href="#" @click="onChangeEmail()">{{$t('sendEmailVerification.verify.changeEmailLink')}}</a>
            </p>

            <div class="text-center mt-2">
                <a target="_blank" href="https://m.me/AssistenzaTecnicaConcorsando" rel="noopener">{{$t('components.login.haiBisognoDiAiuto')}}</a>
            </div>
        </div>

        <!-- modali -->
        <div id="modalSuccess" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalErrorLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalSuccessLabel">{{$t('sendEmailVerification.modale.success.titolo')}}</h5>
                    </div>
                    <div id="modal-success-body" class="modal-body">
                        <p id="message-success-body">{{$t('sendEmailVerification.verify.onVerifySuccess')}}</p>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect waves-light" @click="finishVerificaEmailFlow()" data-dismiss="modal">{{$t('generale.ok')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>


        <div id="modalMessage" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalErrorLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalMessageLabel">{{$t('sendEmailVerification.modale.success.titolo')}}</h5>
                    </div>
                    <div id="modal-message-body" class="modal-body">
                        <p id="message-contents"></p>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.ok')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>

        <div id="modalError" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalErrorLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalErrorLabel">{{$t('sendEmailVerification.modale.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-error-body" class="modal-body">
                        <h5 class="font-size-16">{{$t('sendEmailVerification.modale.errore.sottotitolo')}}</h5>
                        <p id="message-body"></p>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.ok')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>

        <!-- Modal overlay login -->
        <div id="modalOverlayLogin" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalOverlayLoginLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content bg-transparent border-0">
                    <div id="modal-body-overlay-pause" class="modal-body">
                        <div class="w-100 text-center pb-4">
                            <img src="../assets/images/logo-concorsando-white.png">
                        </div>
                    </div>
                </div>
            </div>
            <!-- /.modal-dialog -->
        </div>
        <!-- /Modal overlay login -->
        <!-- /modali -->
    </div>
</template>

<script>
import 'bootstrap'
import $ from 'jquery'
import { email } from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'
import { MODULES } from "../store/modules-enum";
import { AUTH_ACTIONS } from "../store/auth/actions-enum";
import { AUTH_GETTERS } from "../store/auth/getters-enum";
import { routesEnums } from "@/helpers/enums";
import _ from "lodash";
import {LoginStates} from "../flows/login/loginStates";
import {loginTransition} from "../flows/login/loginFlow";
import {PROFILE_ACTIONS} from "../store/profile/actions-enum";
import {isDocumentHidden, registerVisibilityChange, unregisterVisibilityChange} from "../helpers/utilities";
import firebase from "firebase";

export default {
    name: "sendEmailVerification",
    data () {
        return {
            form: {
                email: '',
            },
            nonce: undefined,
            allowEditEmail: false,
            hidden: false,
        }
    },
    computed: {
        ...mapGetters(MODULES.AUTH, [AUTH_GETTERS.AUTHENTICATIONS, AUTH_GETTERS.EMAIL_COMUNICAZIONI, AUTH_GETTERS.PROFILE]),
        canChangeEmail() {
            return this.authentications.social !== "concorsando";
        }
    },
    mounted() {
        // this.verifyPreFilledFields(this.$route.params)
        registerVisibilityChange(this.performImplicitFlow, false);
    },
    beforeDestroy() {
        unregisterVisibilityChange(this.performImplicitFlow)
        this.hideOverlay();
    },
    validations: {
        form: {
            email: {
                email
            }
        }
    },
    methods: {
        ...mapActions(MODULES.PROFILE, [PROFILE_ACTIONS.UPDATE_USER, PROFILE_ACTIONS.SEND_VERIFY_EMAIL, PROFILE_ACTIONS.GET_USER]),
        ...mapActions(MODULES.AUTH, [AUTH_ACTIONS.UPDATE_AUTHENTICATION_STATUS]),
        performImplicitFlow() {
            if (isDocumentHidden()) {
                this.hidden = true;
            } else {
                if (this.hidden) {
                    this.hidden = false;
                    this.onHoVerificato(true);
                }
            }
        },
        onHoVerificato(isImplicit = false) {
            $('#modalOverlayLogin').modal({backdrop: 'static', keyboard: false})
            const checkEmailPromise = this.getUser()
                .then((profile) => {
                    if (profile && profile.email_verificata) {
                        firebase.analytics().logEvent('web_email_verified')
                        window.fbq('trackCustom', 'web_email_verified')
                        this.updateAuthenticationStatus()
                        return true;
                    } else {
                        return Promise.reject()
                    }
                })

            checkEmailPromise
                .then(() => {
                    setTimeout(() => {
                        this.hideOverlay();
                        this.finishVerificaEmailFlow()
                        // if (isImplicit)
                        //     this.finishVerificaEmailFlow()
                        // else
                        //     $('#modalSuccess').modal('show')
                    }, 500)
                })
                .catch(() => {
                    setTimeout(() => {
                        this.hideOverlay();
                        const msg = this.$t('sendEmailVerification.verify.onVerifyError')
                        if (!isImplicit)
                            this.showMessage(msg, "ERROR")
                    }, 500)
                })
        },
        showMessage(msg, type) {
            if (type === "ERROR") {
                $('#modalError').modal('show')
                $('#message-body').html(msg)
            } else {
                $('#modalMessage').modal('show')
                $('#message-contents').html(msg)
            }
        },
        finishVerificaEmailFlow() {
            loginTransition(LoginStates.CHECK_EMAIL)
        },
        onChangeEmail() {
            this.form.email = '';
            this.allowEditEmail = true;
        },
        onSendNewVerification() {
            $('#modalOverlayLogin').modal({backdrop: 'static', keyboard: false})
            const sendNewVerificationPromise = this.sendVerifyEmail({
                email: this.emailComunicazioni,
                id_utente_concorsando: this.profile.id_utente_concorsando
            })

            sendNewVerificationPromise
                .then(() => {
                    setTimeout(() => {
                        this.hideOverlay();
                        const msg = this.$t('sendEmailVerification.verify.onSendSuccess')
                        this.showMessage(msg, "MESSAGE")
                    }, 500)
                })
                .catch(() => {
                    setTimeout(() => {
                        this.hideOverlay();
                        const msg = this.$t('sendEmailVerification.modale.errore.generale')
                        this.showMessage(msg, "ERROR")
                    }, 500)
                })
        },
        onCancelNewEmail() {
            this.allowEditEmail = false;
            // TODO: reset email nel form
        },
        onConfirmNewEmail() {
            this.$v.$touch()
            if (!this.$v.$invalid) {
                $('#modalOverlayLogin').modal({backdrop: 'static', keyboard: false})
                const changeEmailPromise = this.updateUser({
                    ...this.profile,
                    email_comunicazioni: this.form.email,
                })
                    .then(() => this.getUser())

                changeEmailPromise
                    .then(() => {
                        setTimeout(() => {
                            this.hideOverlay();

                            this.allowEditEmail = false;
                            const msg = this.$t('sendEmailVerification.change.onChangeSuccess')
                            this.showMessage(msg, "MESSAGE")
                        }, 500)
                    })
                    .catch(() => {
                        setTimeout(() => {
                            this.hideOverlay();
                            // TODO: catturare l'errore per cui l'email è già stata usata per un altro account
                            const msg = this.$t('sendEmailVerification.modale.errore.generale')
                            this.showMessage(msg, "ERROR")
                        }, 500)
                    })
            }
        },
        hideOverlay (updateNonce = false) {
            if (updateNonce) {
                this.nonce = Date.now();
            }
            $('#modalOverlayLogin').modal('hide');
        }
    }
}
</script>

<style scoped lang="scss">
html.theme-light, html.theme-dark {
    .pl-vertical-align-input {
        padding-left: 14px;
    }

    #modalOverlayLogin {
        background-color: #00000082;
    }
}
</style>
